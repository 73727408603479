<template>
	<div style="" class="header">
		<van-nav-bar title="生日花语" left-arrow @click-left="onClickLeft" fixed placeholder />

		<div class="container">
			<p>{{msg}}</p>

			<div class="ge-totateBox">
				<van-popover class="select" v-model="showPopover" trigger="click" :actions="mon" @select="onSelect">
					<template #reference>
						<div class="ge-icon">
							<span style="margin-right: 4px;">{{selectMon ? selectMon + '月' : "出生月份"}}</span>
							<span>
								<van-icon size="10" name="play" :class="{rotate: showPopover === true}"
									style="transform: rotate(90deg);" />
							</span>
						</div>
					</template>
				</van-popover>
				
				<van-popover class="select" v-model="showPopoverRight" trigger="click" :actions="day4"
					@select="onSelectRight">
					<template #reference>
						<div class="ge-icon">
							<span style="margin-right: 7px;">{{selectDay ? selectDay + '日'  : "出生日期"}}</span>
							<span>
								<van-icon size="10" name="play" :class="{rotateRight: showPopoverRight === true}"
									style="transform: rotate(90deg);" />
							</span>
						</div>
					</template>
				</van-popover>
			</div>

			<van-button color="#FF8787" type="primary" size="large" @click="ask">生日花语查询</van-button>
		</div>
		
		<div class="flower-language" v-if="result">
			<!-- <div class="flower-language-top">{{ flowerLanguageTop }}</div> -->
			<div class="flower-language-bottom">
				<p class="flower-language-bottom-tittle">生气花语：{{result.name}}</p>
				<p class="flower-language-bottom-content" v-html="result.name_des"></p>
				<p class="flower-language-bottom-tittle">花语：{{result.lang}}</p>
				<p class="flower-language-bottom-content" v-html="result.lang_des"></p>
				<p class="flower-language-bottom-tittle">诞生石：{{result.stone}}</p>
				<p class="flower-language-bottom-content" v-html="result.stone_des + result.legend">
				</p>
			</div>
		</div>
		<div class="flower-language" v-if="!result">
			<!-- <div class="flower-language-top">{{ flowerLanguageTop }}</div> -->
			<div class="flower-language-bottom">
				<p class="flower-language-bottom-content">
					生日花语会告诉大家玫瑰代表爱情、爱与美、容光焕
发，郁金香代表爱的表白、荣誉、祝福永恒，百合代
表顺利、心想事成、祝福、高贵，康乃馨代表母亲我
爱您、热情、真情，鸢尾(爱丽斯)代表好消息、使者、
想念你，向日葵代表爱慕、光辉、忠诚，牡丹代表圆
满、浓情、富贵。 您的生日花语是什么?快到生日花
语这里找答案吧～</p>

				<p class="flower-language-bottom-content">
				生日花语会告诉大家玫瑰代表爱情、爱与美、容光焕
				发，郁金香代表爱的表白、荣誉、祝福永恒，百合代
				表顺利、心想事成、祝福、高贵，康乃馨代表母亲我
				爱您、热情、真情，鸢尾(爱丽斯)代表好消息、使者、
				想念你，向日葵代表爱慕、光辉、忠诚，牡丹代表圆
				满、浓情、富贵。 您的生日花语是什么?快到生日花
				语这里找答案吧～
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		flowerLanguageTop
	} from './flowerLanguage'
	import {
		getDirthday
	} from '@/api/birthdayLibrary'
	export default {
		data() {
			return {
				showPopover: false,
				actions: [{
					text: '选项一'
				}, {
					text: '选项二'
				}, {
					text: '选项三'
				}],
				showPopoverRight: false,
				actionsRight: [{
					text: '选项一'
				}, {
					text: '选项二'
				}, {
					text: '选项三'
				}],
				mon:[{text:1},{text:2},{text:3},{text:4},{text:5},{text:6},{text:7},{text:8},{text:9},{text:10},{text:11},{text:12}],
				day1:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
				day2:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29],
				day3:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
				day4:[{text:1},{text:2},{text:3},{text:4},{text:5},{text:6},{text:7},{text:8},{text:9},{text:10},{text:11},{text:12},{text:13},{text:14},{text:15},{text:16},{text:17},{text:18},{text:19},{text:20},{text:21},{text:22},{text:23},{text:24},{text:25},{text:26},{text:27},{text:28},{text:29},{text:30},{text:31}],
				flowerLanguageTop: flowerLanguageTop,
				flowerLanguageBottom: '',
				selectDay:'',
				selectMon:'',
				msg:'请输入您的生日月份与日期',
				result:null
			}
		},

		watch: {
			showPopover(val) {
				if (val) {
					this.showPopover = true
				}
			},

			showPopoverRight(val) {
				if (val) {
					this.showPopoverRight = true
				}
			}
		},

		methods: {
			onClickLeft() {
					let u = navigator.userAgent;
					let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
					let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
					if (isIOS) {
						window.webkit.messageHandlers.close.postMessage("up");
					}
					if (isAndroid) {
						window.android.close();
					}
					// console.log('返回');
					// this.$router.go(-1)
			},

			onSelect(action) {

				this.selectMon = action.text
			},

			onSelectRight(action) {

				this.selectDay = action.text
			},
			ask() {
				var date = '2022' + '-' +('0' + this.selectMon).slice(-2) + '-' + ('0' + this.selectDay).slice(-2)
				getDirthday({keyword:date}).then(res => {
					this.result = res.data.data.result
					this.msg = '您好，您的生日是'+ this.selectMon + '月' + this.selectDay+'日 ，以下为测试结果'
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.header {
		background: url(../../../assets/GeImg/birthday.png) no-repeat;
		background-size: cover;
		height: 100%;
	}

	/deep/ .van-nav-bar {
		background: transparent;
	}

	/deep/ .van-nav-bar__left {
		padding: 0 5px;
	}

	.container,
	.flower-language {
		background: white;
		margin: 30px 10px 20px;
		border-radius: 8px;
		padding: 20px;
		box-sizing: border-box;
	}

	.ge-icon {
		border: 1px solid #DDDDDD;
		border-radius: 3px;
		width: 100%;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0;
	}

	.select {
		width: 46%;
	}

	.rotate {
		transform: rotate(270deg) !important;
		transition: .3s;
	}

	.rotateRight {
		transform: rotate(270deg) !important;
		transition: .3s;
	}

	.ge-totateBox {
		display: flex;
		justify-content: space-between;
		margin: 30px 0 20px;
	}

	.van-button--large {
		height: 44px;
	}

	.flower-language {
		color: #333;
		max-height: 52%;
		overflow: auto;

		&-bottom {
			font-size: 13px;

			.flower-language-bottom-tittle {
				color: #333;
				font-weight: bold;
			}

			.flower-language-bottom-content {
				color: #999;
			}
		}

		&-bottom p {
			margin-bottom: 10px;
		}
	}

	.van-popover__content{
		height: 300px !important;
		overflow-y: auto !important;
	}
</style>
<style lang="less">
	.van-popover__content{
		height: 300px !important;
		overflow-y: auto !important;
	}
</style>
